import { ChakraProvider } from "@chakra-ui/react";
import SearchStudent from "./components/SearchStudentForm";

function App() {
  return (
    <ChakraProvider>
      <SearchStudent />
    </ChakraProvider>
  );
}

export default App;
