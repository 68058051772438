import React, { useState } from "react";
import {
  Container,
  FormControl,
  FormLabel,
  Input,
  Button,
  Text,
  Center,
  Flex,
} from "@chakra-ui/react";

import axios from "axios";
import { QRCodeSVG } from "qrcode.react";

function SearchStudent() {
  const [email, setEmail] = useState("");
  const [cpf, setCpf] = useState("");
  const [ra, setRa] = useState("");
  const [message, setMessage] = useState("");
  const [discount, setDiscount] = useState(null);
  const [pix, setPix] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage("");
    setPix(null);

    const response = await axios.post(process.env.REACT_APP_API, {
      email: email,
      cpf: cpf,
      ra: ra,
      discount: discount || 0,
    });

    const negotiation = response?.data;

    if (negotiation && negotiation.error) setMessage(negotiation.error);
    else setPix(negotiation);
  };

  return (
    <Flex direction="column" w="100vw" align="center">
      <Container maxW="md" mt={10}>
        <form onSubmit={handleSubmit}>
          <FormControl mb={4}>
            <FormLabel>E-mail</FormLabel>
            <Input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </FormControl>
          <FormControl mb={4}>
            <FormLabel>CPF</FormLabel>
            <Input
              type="text"
              value={cpf}
              onChange={(e) => setCpf(e.target.value)}
            />
          </FormControl>
          <FormControl mb={4}>
            <FormLabel>RA</FormLabel>
            <Input
              type="text"
              value={ra}
              onChange={(e) => setRa(e.target.value)}
            />
          </FormControl>
          <FormControl mb={4}>
            <FormLabel>Desconto</FormLabel>
            <Input
              type="number"
              value={discount}
              onChange={(e) => setDiscount(e.target.value)}
            />
          </FormControl>
          <Center>
            <Button
              colorScheme="red"
              borderRadius="5px"
              minW="200px"
              type="submit"
            >
              Consultar
            </Button>
          </Center>
        </form>
        {message && (
          <Text textAlign="center" mt={4} color="gray.700">
            {message}
          </Text>
        )}
      </Container>
      {pix && (
        <Flex
          mt="24px"
          direction="column"
          maxW="75%"
          align="center"
          justify="center"
        >
          <Text>ID de pagamento: {pix?.payment_id}</Text>
          <Text>ID da transação: {pix?.additional_data?.transaction_id}</Text>
          <Text>
            Data e hora de criação: {pix?.additional_data?.creation_date_qrcode}
          </Text>
          <Text mb="10px">
            Linha digitável: {pix?.additional_data?.qr_code}
          </Text>
          <QRCodeSVG size={256} value={pix?.additional_data?.qr_code} />
        </Flex>
      )}
    </Flex>
  );
}

export default SearchStudent;
